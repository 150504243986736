<template>
    <div v-if="ptInfo">
        <vvo v-slot="v">
            <b-modal ref="rltnPointModal" centered size="md" @hidden="hideRelationPointModal">
                <template #modal-header>
                    <!-- ptInfo.pointInfo.ptIdx -->
                    <span class="ctrl-modal-header">
                        {{
                            ptInfo.pointInfo.ptIdx
                                ? "연관 관제점 편집"
                                : type == "ctrl"
                                ? "영향 인자 추가"
                                : "연관 관제점 추가"
                        }}
                    </span>
                </template>
                <template #modal-footer>
                    <div style="display: flex; flex: 1">
                        <!-- @click="savePoint" -->
                        <button type="button" class="btn btn-primary ctrl-modal-btn" @click="onValidate(v, savePoint)">
                            저장
                        </button>
                        <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideRelationPointModal">
                            닫기
                        </button>
                    </div>
                </template>
                <div class="detail-container">
                    <div class="detail-content">
                        <div class="row">
                            <div class="col-md-7">
                                <label for="ptInfo">연결된 관제점(*)</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="관제점을 찾아서 연결해주세요."
                                    :value="ptInfo.pointInfo ? ptInfo.pointInfo.ptAddr : ''"
                                    disabled
                                />
                            </div>
                            <div class="col-md-2">
                                <label for="ptIdx">관제점 ID</label>
                                <!-- <input type="text" class="form-control" placeholder="-" 
                                    :value="ptInfo.pointInfo ? ptInfo.pointInfo.ptIdx : ''" :disabled="!inputPoint"> -->
                                <input
                                    v-model="ptInfo.pointInfo.ptIdx"
                                    type="text"
                                    class="form-control"
                                    placeholder="-"
                                    @keydown.enter="enterSelectPoint"
                                    :disabled="!inputPoint"
                                />
                            </div>
                            <div
                                class="col-md-3"
                                style="
                  display: flex;
                  flex: 1;
                  align-items: end;
                  justify-content: end;
                "
                            >
                                <button
                                    v-b-toggle.rltnPointSideBar
                                    type="button"
                                    class="btn btn-primary"
                                    style="flex: 1"
                                >
                                    관제점 선택
                                </button>
                                <!-- <button type="button" class="btn btn-sm btn-secondary" @click="inputSearchPoint">직접 입력</button> -->
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="name">표시명</label>
                                <!-- <input v-model="ptInfo.dispText" type="text" class="form-control" id="name"> -->
                                <valid-input
                                    :vid="'표시명'"
                                    :classList="'form-control'"
                                    :inputType="'text'"
                                    :inputValue.sync="ptInfo.dispText"
                                    :placeholder="$t('표시명')"
                                    :rules="rules.COMMON_NAME_RULE"
                                    :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                                >
                                </valid-input>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="enabled">사용여부</label>
                                <select v-model="ptInfo.enabled" class="form-control" id="enabled">
                                    <option :value="null">선택</option>
                                    <option
                                        v-for="enabled in commonCodes.enabled"
                                        :key="enabled.value"
                                        :value="enabled.value"
                                    >
                                        {{ enabled.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="ptReltnType">연관 유형</label>
                                <!-- <select v-model="ptInfo.ptReltnType" class="form-control" id="ptReltnType">
                                    <option :value="null">선택</option>
                                    <option v-for="(type) in commonCodes.ptReltnType" :key="type.value"
                                        :value="type.value">
                                        {{ type.text }}
                                    </option>
                                </select> -->
                                <valid-select
                                    :vid="`연관 유형`"
                                    :vidName="'연관 유형'"
                                    :inputValue.sync="ptInfo.ptReltnType"
                                    :selectOption="commonCodes.ptReltnType"
                                    :rules="rules.COMMON_SELECT_REQUIRED"
                                    :useCommonCode="true"
                                    :errorMessage="validMessage.EQUIP_TYPE_CATEGORY_VALID_MESSAGE"
                                >
                                </valid-select>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="affectRange">영향 범위</label>
                                <!-- <select v-model="ptInfo.affectRange" class="form-control" id="affectRange">
                                    <option :value="null">선택</option>
                                    <option v-for="(type) in commonCodes.affectRange" :key="type.value"
                                        :value="type.value">
                                        {{ type.text }}
                                    </option>
                                </select> -->
                                <valid-select
                                    :vid="`영향 범위`"
                                    :vidName="'영향 범위'"
                                    :inputValue.sync="ptInfo.affectRange"
                                    :selectOption="commonCodes.affectRange"
                                    :rules="rules.COMMON_SELECT_REQUIRED"
                                    :useCommonCode="true"
                                    :errorMessage="validMessage.EQUIP_TYPE_CATEGORY_VALID_MESSAGE"
                                >
                                </valid-select>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="expansionModal" class="col-md-6" style="padding: 1rem;">
                    <Search :tableMaxHeight="'32vh'" :optionTableHeight="'24vh'" @select-point="selectPoint"
                        @close="searchPoint" />
                </div> -->
                </div>
                <b-sidebar
                    v-model="sideBar"
                    ref="rltnPointSideBar"
                    id="rltnPointSideBar"
                    bg-variant="white"
                    backdrop-variant="secondary"
                    width="50vw"
                    @hidden="closeSidebar"
                    no-header
                    shadow
                    right
                    backdrop
                >
                    <div v-if="sideBar" style="padding: 1rem; height: 100%">
                        <SearchComponent
                            :tableMaxHeight="'75vh'"
                            :optionTableHeight="'67vh'"
                            @select-point="selectPoint"
                            @close="closeSidebar"
                        />
                    </div>
                </b-sidebar>
            </b-modal>
        </vvo>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import backEndApi from "@src/api/backEndApi";
import SearchComponent from "@src/views/component/v2.1/PointSearch/Search.vue";
import { mapState } from "vuex";

export default {
    props: ["data", "type"],
    components: {
        SearchComponent,
    },
    data() {
        return {
            ptInfo: null,
            // expansionModal: false,
            inputPoint: false,
            sideBar: false,
            rules,
            validMessage,
        };
    },
    watch: {},
    computed: {
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
        ...mapState({
            zoneInfo: (state) => state.zoneInfo,
        }),
        ...mapState({
            transSysType: (state) => state.transSysType,
        }),
        ...mapState({
            transEquipType: (state) => state.transEquipType,
        }),
    },
    created() {},
    mounted() {},
    methods: {
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.rltnPointSideBar.hide();
            this.sideBar = false;
        },
        initData() {
            return {
                sysNodeIdx: this.data.sysNodeIdx,
                dispText: null,
                ptReltnType: this.type == "ctrl" ? "Factor" : null,
                affectRange: this.type == "ctrl" ? "Private" : null,
                enabled: "Y",
                pointInfo: {
                    ptIdx: null,
                }, //Object
            };
        },
        setData(data) {
            if (data) {
                this.ptInfo = data;
            } else {
                this.ptInfo = this.initData();
            }
        },
        showRelationPointModal() {
            this.$refs.rltnPointModal.show();
        },
        hideRelationPointModal() {
            // this.expansionModal = false;
            this.$refs.rltnPointModal.hide();
        },
        searchPoint() {
            // this.expansionModal = !this.expansionModal;
        },
        async enterSelectPoint() {
            try {
                let result = await backEndApi.points.getPointInfoById(this.ptInfo.pointInfo.ptIdx);
                if (result.status == 200) {
                    this.ptInfo.pointInfo = result.data[0];
                } else {
                    this.alertWarning(`${result.data.message}`);
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        },
        inputSearchPoint() {
            this.inputPoint = !this.inputPoint;
        },
        selectPoint(data) {
            if (!this.ptInfo.dispText) {
                this.ptInfo.dispText = data.ptName;
            }
            this.ptInfo.pointInfo = data;
            this.$refs.rltnPointSideBar.hide();
        },
        savePoint() {
            this.$emit("save");
        },
    },
};
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    /* border: solid #eee 1px; */
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-content {
    /* height: 88%; */
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    /* border-right: solid #eee 1px; */
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

/* .ptSelect .btn-sm {
    font-size: 0.625rem !important;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem !important;
} */
</style>
