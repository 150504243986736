<template>
    <div>
        <b-modal
            ref="dataBaseModal"
            centered
            @hidden="closeDataBaseModal"
            size="lg"
            hide-footer
            :title="`${title} 데이터`"
        >
            <template #default>
                <DataTable :value="tableDatas" scrollable :scrollHeight="'calc(100vh - 300px)'">
                    <template #header>
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div>
                                <span class="m-0 table-title">데이터</span>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm btn-outline-secondary" @click="exportToCSV">
                                    Export
                                </button>
                            </div>
                        </div>
                    </template>
                    <Column
                        field="regDt"
                        header="날짜"
                        :headerStyle="{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }"
                        :bodyStyle="{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }"
                    >
                        <template #body="slotProps">
                            {{ formatRegDt(slotProps.data.regDt) }}
                        </template>
                    </Column>

                    <Column
                        v-for="(header, key) in tableHeaders"
                        :key="key"
                        :field="header.text"
                        :header="`${header.text} (${header.sumUnit})`"
                        :headerStyle="{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }"
                        :bodyStyle="{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }"
                    />
                </DataTable>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        DataTable,
        Column,
    },
    props: ["flag", "data", "title"],
    data() {
        return {
            tableDatas: [],
            tableHeaders: [],
            popupMessages,
        };
    },
    watch: {
        flag(newValue) {
            if (newValue) {
                this.showDataModal();
            } else {
                this.closeDataBaseModal();
            }
        },
        data(newValue) {
            this.tableHeaders = this.extractDataRecursive(newValue.trendData.headers);
            this.tableDatas = this.makeTableDatas(newValue);
        },
    },
    mounted() {
        if (this.data) {
            this.tableHeaders = this.extractDataRecursive(this.data.trendData.headers);
            this.tableDatas = this.makeTableDatas(this.data);
        }
    },

    computed: {
        regDtLabel() {
            return this.data[0].data.labels;
        },
    },
    methods: {
        formatRegDt(regDt) {
            if (!regDt) return "";
            return regDt.includes("T") ? regDt.split("T").join(" ") : regDt;
        },

        exportToCSV() {
            const escapeCSVValue = (value) => {
                if (typeof value === "string" && value.includes(",")) {
                    return `"${value.replace(/"/g, '""')}"`;
                }
                return value;
            };

            const formatRegDt = (regDt) => {
                if (!regDt) return "";
                return regDt.includes("T") ? regDt.split("T").join(" ") : regDt;
            };

            const csvData = [
                ["날짜", ...Object.values(this.tableHeaders).map((header) => escapeCSVValue(header.text))],
                ...this.tableDatas.map((row) => [
                    escapeCSVValue(formatRegDt(row.regDt)),
                    ...Object.values(this.tableHeaders).map((header) => escapeCSVValue(row[header.text])),
                ]),
            ];

            const csvContent = "\uFEFF" + csvData.map((e) => e.join(",")).join("\n");

            const fileName = `download.csv`;
            const link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent));
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.alertNoti(popupMessages.COMMON_DOWNLOAD_SUCCESS_POPUP_MESSAGE);
        },

        extractDataRecursive(node) {
            const result = {
                [node.sysNodeIdx]: {
                    text: node.text,
                    sumUnit: node.sumUnit,
                },
            };

            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    result[child.sysNodeIdx] = {
                        text: child.text,
                        sumUnit: child.sumUnit,
                    };
                }
            }

            return result;
        },
        makeTableDatas(data) {
            const tabledata = data.trendData.results.map((result) => {
                const newResult = {};

                Object.keys(this.tableHeaders).forEach((key) => {
                    newResult[this.tableHeaders[key].text] = result[key];
                });

                newResult["regDt"] = result.regDt;

                return newResult;
            });

            return tabledata;
        },
        showDataModal() {
            if (this.$refs.dataBaseModal) {
                this.$refs.dataBaseModal.hide();
                this.$refs.dataBaseModal.show();
            }
        },
        closeDataBaseModal() {
            this.$refs.dataBaseModal.hide();
            this.$emit("closeDataBaseModal");
        },
    },
};
</script>

<style scoped></style>
