<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height: 100%">
            <div class="list-area col-3" :style="{ display: expandedTree ? 'none' : '' }">
                <div class="row">
                    <header-box-component
                        :title="
                            `${$t('건물 운영 관리')} > ${$t(
                                `${$route.params.type == 'energy' ? '건물 에너지 운영 현황' : '계통 및 설비 운영 현황'}`
                            )}`
                        "
                        :goBack="true"
                    />
                </div>
                <div class="tree-header">
                    <div class="tree-title col-8">
                        <div>
                            <!-- <span class="title">{{ $t('설비계통') }}</span> -->
                            <span class="title">
                                {{ $t(`${$route.params.type == "energy" ? "에너지 계통" : "설비 계통"}`) }}
                            </span>
                        </div>
                        <div class="flex-grow-1">
                            <select v-model="rootIdx" class="form-control" @change="getTreeList(rootIdx)">
                                <option value="">선택</option>
                                <option v-for="list in rootList" :key="list.sysNodeIdx" :value="list.sysNodeIdx">
                                    {{ list.sysNodeName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="tree-area" v-if="Array.isArray(treeData) && treeData.length > 0 && isShow">
                    <!-- @node:dragging:start="logDragStart" @node:dragging:finish="logDragFinish" @node:expanded="onNodeExpand" -->
                    <liquor-tree
                        class="tree-item"
                        ref="tree"
                        :data="treeData"
                        :options="treeOptions"
                        @node:selected="onNodeSelected"
                    >
                        <div
                            slot-scope="{ node }"
                            class="node-container d-flex align-items-center"
                            @contextmenu.prevent="showContextMenu($event, node)"
                            @click="hideContextMenu"
                        >
                            <span
                                class="treenode-icon"
                                :style="{ color: node.data.sumEnabled == 'Y' ? '#EF5350' : '' }"
                            >
                                <!-- 계통노드 아이콘 -->
                                <i
                                    v-if="node.data.nodeType == 'Root' || node.data.nodeType == 'System'"
                                    class="fas fa-sitemap"
                                    aria-hidden="true"
                                ></i>
                                <!-- 그룹노드 아이콘 -->
                                <i
                                    v-else-if="node.data.nodeType == 'Group'"
                                    class="fas fa-folder-open"
                                    aria-hidden="true"
                                ></i>
                                <!-- 설비노드 아이콘 : 설비유형의 아이콘 -->
                                <i
                                    v-else
                                    :class="
                                        node.data.equipType
                                            ? $store.state.equipTypeList.find(
                                                  (item) => item.equipType == node.data.equipType
                                              ).icon
                                            : ''
                                    "
                                    aria-hidden="true"
                                ></i>
                            </span>
                            {{ node.text }}
                        </div>
                    </liquor-tree>
                </div>
                <div v-else class="tree-area-placeholder">
                    <span class="detail-notification-icon">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    <span class="detail-notification-text">설비 계통을 선택하세요.</span>
                </div>
            </div>

            <!-- Detail Panel -->
            <div class="detail-area col-9" :class="expandedTree ? 'col-12' : ''">
                <tree-detail
                    v-if="currentNode"
                    :currentNode="currentNode"
                    @setExpandedTree="setExpandedTree"
                    :expanded="expandedTree"
                />
                <div v-else class="tree-area-placeholder">
                    <span class="detail-notification-icon">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    <span class="detail-notification-text">계통노드를 선택하세요.</span>
                </div>
            </div>

            <!-- PopUp & Modal -->
            <div
                class="dropdown-menu dropdown-menu-sm"
                :style="{
                    display: contextMenuVisible ? 'block' : 'none',
                    top: contextMenuTop + 'px',
                    left: contextMenuLeft + 'px',
                }"
            >
                <span class="dropdown-item" style="cursor: pointer" @click="openModal">그룹 제어</span>
            </div>

            <EquipControlModal
                v-if="equipData"
                ref="EquipControl"
                :ctrlType="'Group'"
                :equipData="equipData"
                @equip-control="equipControl"
                @clear-data="clearData"
            />
            <AddNodeModal ref="AddNode" @add-node="AddSysNode" />
        </div>
    </div>
</template>

<script>
import HeaderBoxComponent from "@views/component/headerBox/analysis/Header.vue";
import LiquorTree from "liquor-tree";
import EquipControlModal from "./modal/EquipControlModal.vue";
import TreeDetail from "./EquipMgmtCtrlDetail.vue";
import AddNodeModal from "./modal/AddNodeModal.vue";
import backEndApi from "@src/api/backEndApi";
import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        HeaderBoxComponent,
        TreeDetail,
        LiquorTree,
        EquipControlModal,
        AddNodeModal,
    },
    props: [],
    data() {
        return {
            isShow: true,
            treeData: null,
            treeOptions: {
                parentSelect: true,
                dnd: false,
            },
            contextMenuVisible: false,
            contextMenuTop: 0,
            contextMenuLeft: 0,
            selectedNode: null,
            rootList: null,
            rootIdx: null,

            currentNode: null,
            equipCheck: true,
            equipData: null,
            groupCtrlInfo: null,

            expandedNodes: {},
            rltdTrendData: null,
            expandedTree: false,
            today: moment(new Date()).format("YYYY-MM-DD"),
            popupMessages,
        };
    },
    computed: {},
    watch: {
        treeData: function() {
            this.isShow = false;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
    },
    async created() {
        await this.getRootList();
        this.rootIdx = this.$route.params.sysNodeIdx;
        await this.getTreeList(this.rootIdx);
    },
    async mounted() {
        document.addEventListener("click", this.handleDocumentClick);
    },
    destroyed() {
        document.removeEventListener("click", this.handleDocumentClick);
    },
    methods: {
        setExpandedTree() {
            this.expandedTree = !this.expandedTree;
        },
        async equipControl(data) {
            let ctrlCmd = {
                sysNodeIdx: this.currentNode.sysNodeIdx,
                equipType: data[0].equipType,
                ctrlMode: "Basic",
                expandSearch: "Y",
                propList: [
                    {
                        propCode: data[0].ioProps[0].propCode,
                        ptVal: data[0].ioProps[0].ptVal,
                    },
                ],
            };
            try {
                let result = await backEndApi.sysNode.sendGroupCtrlCmd(ctrlCmd);
                if (result.data[0].result === "Success") {
                    await this.alertNoti(popupMessages.SYSTEM_EQUIP_CONTROL_SUCCESS_MESSAGE);
                } else this.alertNoti(popupMessages.SYSTEM_EQUIP_CONTROL_ERROR_MESSAGE);
            } catch (e) {
                console.error(e);
            }
        },

        async onNodeSelected(node) {
            this.currentNode = node.data;
        },
        async getRootList() {
            try {
                let routeType = this.$route.params.type;
                let result = await backEndApi.sysNode.getRootNodeList("Y");
                if (result.status == 200) {
                    this.rootList = result.data;
                    if (routeType == "energy") this.rootList = result.data.filter((item) => item.sumAvail == "Y");
                    else this.rootList = result.data.filter((item) => item.sumAvail == "N");
                } else await this.alertWarning(`${result.data.massage}`);
            } catch (e) {
                console.error(e);
            }
        },
        async getTreeList(rootIdx) {
            this.treeData = null;
            this.currentNode = null;
            this.selectNodeStatus = null;
            let idx = rootIdx ? rootIdx : this.rootIdx;
            if (idx) {
                try {
                    let result = await backEndApi.sysNode.getSysNodeTree(idx);
                    if (result.data) {
                        let newNode = await this.getCustomTreeData(result.data);
                        this.treeData = newNode;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
        getCustomTreeData(data) {
            return data.map((node) => {
                const { text, children, ...rest } = node;
                return {
                    text,
                    children: children ? this.getCustomTreeData(children) : [],
                    state: { expanded: true },
                    data: rest,
                };
            });
        },
        async handleButtonClick(event, options) {
            const that = this;
            switch (event) {
                case "regist":
                    await that.openAddModal();
                    break;

                case "delete":
                    await that.deleteSysNode();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        openAddModal() {
            if (this.currentNode) this.$refs.AddNode.showAddNodeModal();
            else return;
        },
        async AddSysNode(nodeInfo) {
            let newNode = {
                nodeType: nodeInfo.nodeType,
                sysNodeName: nodeInfo.sysNodeName,
                sysType: this.currentNode.sysType,
                equipType: this.currentNode.equipType,
                mainNodeIdx: this.currentNode.sysNodeIdx,
            };
            try {
                let result = await backEndApi.sysNode.addSysNode(newNode);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    await this.getTreeList();
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async deleteSysNode() {
            try {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;
                else {
                    let result = await backEndApi.sysNode.deleteSysNode(this.currentNode.sysNodeIdx);
                    if (result.status == 200) {
                        await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                        await this.getTreeList();
                    } else {
                        this.alertWarning(
                            `${result.data.message}`,
                            "",
                            `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                        );
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        handleDocumentClick() {
            if (this.contextMenuVisible) {
                this.hideContextMenu();
            }
        },
        showContextMenu(event, node) {
            let findSystem = this.$store.state.systemTypeList.find((type) => type.sysType == node.data.sysType);
            if (
                node.children.length == 0 ||
                node.data.nodeType === "Root" ||
                node.data.nodeType === "Equip" ||
                findSystem.sumAvail == "Y"
            ) {
                return;
            }

            this.contextMenuTop = event.pageY + 10;
            this.contextMenuLeft = event.pageX + 10;
            this.contextMenuVisible = true;
            this.groupCtrlInfo = node.data;
        },
        hideContextMenu() {
            this.contextMenuVisible = false;
        },
        onNodeExpand(node) {
            const expandedNodes = this.$refs.tree.findAll({
                state: { expanded: true },
            });
            // const allNodes = this.$refs.tree.findAll();

            expandedNodes.forEach((expandedNode) => {
                if (expandedNode.id !== node.id && node.depth === expandedNode.depth) {
                    // expandedNode.collapse()
                    expandedNode.state.expanded = expandedNode.state.expanded || false;
                }
            });
        },
        async openModal() {
            this.equipData = null;
            try {
                let result = await backEndApi.sysNode.getIncludedEquipStatus(
                    this.groupCtrlInfo.sysNodeIdx,
                    this.groupCtrlInfo.equipType
                );
                if (result.status == 200) {
                    this.equipData = result.data;
                    this.$nextTick(() => {
                        this.$refs.EquipControl.showControlModal();
                    });
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        clearData() {
            this.equipData = null;
        },
    },
};
</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    padding: 10px 30px 10px 20px;
    overflow: hidden;

    background-color: white;
    border-radius: 10px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
    /* border-right: 1px solid #eee; */
}

.ld-container .detail-area {
    height: 100%;
    margin-right: 0px;
    padding: 0px 10px 0px 10px;

    border-radius: 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
}

.tree-area {
    height: Calc(100% - 70px);
    /* div 높이가 상위 영역 밖으로 벗어나서 - 70px 로 수정(기존 - 36px) */
    width: 100%;

    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding-left: 8px;
    margin-bottom: 0px !important;

    border-radius: 0px 0px 10px 10px;
    border-left: solid #eee 1px;
    border-top: solid #eee 1px;
    border-right: solid #ddd 1px;
    border-bottom: solid #ddd 1px;
    font-size: 0.8rem;
}

.tree-area-placeholder {
    height: Calc(100% - 36px);
    width: 100%;

    /* overflow-y: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 8px 16px 8px 16px;
    margin-bottom: 0px !important;

    border-radius: 0px 0px 10px 10px;
    border-left: solid #eee 1px;
    border-top: solid #eee 1px;
    border-right: solid #ddd 1px;
    border-bottom: solid #ddd 1px;

    font-size: 0.9rem;
    font-weight: bold;
}

.tree {
    padding-left: 10px;
    width: 100%;
    /* overflow-y: auto; */
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.tree-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    /* transition: flex-grow 0.3s ease; */

    /* background-color: #eee; */
    background-color: #f1f1f1;

    padding: 4px 0px 4px 8px;
    margin-bottom: 0px !important;
    font-weight: bold;
    /* display: flex; */
    /* margin-top: 10px; */

    border-radius: 6px 6px 0px 0px;
    border: solid #eee 1px;
    border-bottom: 1px solid #ccc;

    font-size: 0.9rem;
}

.tree-title {
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
}

.tree-title .title {
    margin-right: 8px;
}

.control-buttons {
    /* margin-bottom: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0px;
    /* height: 5%; */
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.check-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.treenode-icon {
    /* color: #727272; */
    color: #5775ad;
    background-color: #f5f5f5;
    border: solid #e2e2e2 1px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: background-color 0.5s;
    width: 26px;
    height: 26px;
    font-size: 16px;
}

.treenode-icon:hover {
    background-color: #c7c7c7;
}

.treenode-icon.active {
    color: #fff;
    background-color: #555;
}

.control-icon {
    color: white;
    background-color: #ed7d31;
    border: solid #fff 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 3rem;
    height: 2rem;
}

.used-icon {
    color: white;
    background-color: #666;
    border: solid #fff 1px;
    text-wrap: nowrap;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 3rem;
    height: 2rem;
}

.used-icon:hover {
    background-color: #999;
}

.control-icon:hover {
    background-color: #eab897;
}

.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 3rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}
</style>
