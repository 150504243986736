<template>
    <div v-if="baseChartData" class="row" style="height: 100%">
        <!-- 부모의 selectedRltdPtIdx와 연계하도록 변경함 by baedblock -->

        <div ref="scatterChart" v-if="rltdTrendData" class="col-9" style="width: 100%; height: 100%"></div>

        <div class="col-3" v-if="!isEmpty(regressionResult)">
            <div class="row m-0">
                <div class="statisticButtonContainer">
                    <button
                        class="col status-icon"
                        :class="{ active: activeIcon === 'statisticReport' }"
                        @click="handleIconClick('statisticReport')"
                        :title="'통계지표'"
                    >
                        <span>분석지표</span>
                    </button>
                    <button
                        class="col status-icon"
                        :class="{ active: activeIcon === 'statisticResult' }"
                        @click="handleIconClick('statisticResult')"
                        :title="'통계지표'"
                    >
                        <span>분석결과</span>
                    </button>
                </div>
            </div>

            <div class="equationContainer">
                Y =
                {{ regressionResult && regressionResult && regressionResult.equation }}
            </div>
            <div v-if="activeIcon === 'statisticReport'" class="row m-0">
                <div class="table-container">
                    <b-table-simple v-if="!isEmpty(regressionResult)" bordered class="text-center">
                        <b-thead>
                            <b-tr>
                                <b-th>지표</b-th>
                                <b-th colspan="2">지표값</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th :rowspan="regressionResult.coefficients.length + 1">회귀계수</b-th>
                                <b-th>Y절편</b-th>
                                <b-td>{{
                                    regressionResult.coefficients[0] ? regressionResult.coefficients[0] : 0
                                }}</b-td>
                            </b-tr>
                            <b-tr v-for="(cfc, cfcIndex) in regressionResult.coefficients" :key="cfcIndex + 10">
                                <b-th v-if="cfcIndex !== 0">{{ `X${cfcIndex}계수` }}</b-th>
                                <b-td v-if="cfcIndex !== 0">{{ cfc ? cfc : 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>R2</b-th>
                                <b-td colspan="2">{{ regressionResult.R2 ? regressionResult.R2 : 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>보정 R2</b-th>
                                <b-td colspan="2">{{
                                    regressionResult.adjustedR2 ? regressionResult.adjustedR2 : 0
                                }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th :rowspan="regressionResult.pValues.length + 1">P-Value</b-th>
                                <b-th>Y절편</b-th>
                                <b-td>{{ regressionResult.pValues[0] ? regressionResult.pValues[0] : 0 }}</b-td>
                            </b-tr>
                            <b-tr v-for="(pVal, pValIndex) in regressionResult.pValues" :key="pValIndex + 20">
                                <b-th v-if="pValIndex !== 0">{{ `X${pValIndex}계수` }}</b-th>
                                <b-td v-if="pValIndex !== 0">{{ pVal ? pVal : 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th :rowspan="regressionResult.vif.length + 1">다중공선성</b-th>
                                <b-th>X1</b-th>
                                <b-th>{{ regressionResult.vif[0] ? regressionResult.vif[0] : 0 }}</b-th>
                            </b-tr>
                            <b-tr v-for="(vif, vifIndex) in regressionResult.vif" :key="vifIndex + 30">
                                <b-th v-if="vifIndex !== 0">{{ `X${vifIndex + 1}` }}</b-th>
                                <b-td v-if="vifIndex !== 0">{{ vif ? vif : 0 }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </div>
            <div v-if="activeIcon === 'statisticResult'" class="row m-0">
                <div class="msgBox">
                    <div
                        v-for="(item, index) in regressionResult.resultMsg"
                        :key="index"
                        v-html="highlightedText(item)"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";

export default {
    data() {
        return {
            chartData: [],
            isDataReady: false,
            activeIcon: "statisticReport",

            highlightingKeyword: ["Adjusted R", "회귀 계수", "p-value", "VIF"],
        };
    },
    props: ["rltdTrendData", "baseChartData", "compIdx", "regressionResult"],
    mounted() {
        this.checkAndPrepareData();
    },
    watch: {
        baseChartData() {
            this.checkAndPrepareData();
        },
        compIdx() {
            this.checkAndPrepareData();
        },
    },

    methods: {
        highlightedText(text) {
            const keywordPattern = this.highlightingKeyword.join("|");
            const regex = new RegExp(`(${keywordPattern})`, "gi");
            return text.replace(regex, '<span class="highlightMsg">$1</span>');
        },
        handleIconClick(icon) {
            this.activeIcon = icon;
        },
        openCommentModal() {
            this.$refs.comment.show();
        },
        closeCommentModal() {
            this.$refs.comment.hide();
        },
        checkAndPrepareData() {
            if (this.isDataValid()) {
                this.isDataReady = true;
                this.prepareChartData();
            } else {
                this.isDataReady = false;
            }
        },
        isDataValid() {
            // 데이터 유효성 검사
            return (
                this.rltdTrendData &&
                this.baseChartData &&
                this.rltdTrendData.headers &&
                this.rltdTrendData.results.length > 0 &&
                this.baseChartData[0] &&
                this.baseChartData[0].data &&
                this.baseChartData[0].data.datasets[0]
            );
        },
        prepareChartData() {
            this.chartData = [];
            if (this.isDataValid()) {
                this.baseChartData[0].data.datasets[0].data.forEach((item, index) => {
                    let xVal = this.rltdTrendData.results[index][this.compIdx];
                    let yVal = item;

                    if (!isNaN(xVal) && !isNaN(yVal)) {
                        // 유효한 값만 추가
                        this.chartData.push([Number(xVal), Number(yVal)]);
                    }
                });

                if (this.chartData.length > 0) {
                    this.renderChart();
                } else {
                    console.warn("No valid data points to render.");
                }
            }
        },
        renderChart() {
            if (!this.$refs.scatterChart || !this.chartData || this.chartData.length === 0) {
                console.warn("Cannot render chart: Data or element reference is missing.");
                return;
            }
            const unit = this.baseChartData[0].data.datasets[0].unit;
            const chart = echarts.init(this.$refs.scatterChart);

            const regression = ecStat.regression("linear", this.chartData);

            regression.points = regression.points.map((point) => [
                parseFloat(point[0].toFixed(3)),
                parseFloat(point[1].toFixed(3)),
            ]);

            const xValues = this.chartData.map((point) => point[0]).filter((val) => val);
            const minX = Math.floor(Math.min(...xValues) / 2) * 2;
            const maxX = Math.ceil(Math.max(...xValues) / 2) * 2;

            const yValues = this.chartData.map((point) => point[1]).filter((val) => val);
            const minY = 0;
            const maxY = Math.ceil(Math.max(...yValues) / 10) * 11;

            const option = {
                title: { text: "산포도 차트", left: "center", show: false },
                textStyle: { fontSize: 10 },
                legend: { bottom: 5, textStyle: { fontSize: 12 } },
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "cross" },
                    formatter: function(params) {
                        let result = `<div style="font-size: 12px; width: 230px;">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((param) => {
                            let valueWithUnit =
                                param.seriesType === "scatter" ? `${param.value[1]} ${unit || "kWh"}` : param.value[1];
                            result += `<div style="display: flex; justify-content: space-between;">
                                            <div>
                                                ${param.marker}
                                                <span>${param.seriesName}</span>
                                            </div>
                                            <span style="font-weight: bold;">${valueWithUnit}</span>
                                        </div>`;
                        });

                        result += `</div></div>`;
                        return result;
                    },
                },
                grid: { left: 65 },
                xAxis: {
                    name: this.rltdTrendData.headers.find((item) => item.ptIdx === this.compIdx).ptName,
                    min: minX,
                    max: maxX,
                    splitLine: { show: false },
                    nameTextStyle: {
                        fontSize: 12,
                        padding: [20, 0, 20, -70],
                        lineHeight: 40,
                        verticalAlign: "top",
                    },
                },
                yAxis: {
                    name: `전력사용량 (${unit || "kWh"})`,
                    min: minY,
                    max: maxY,
                    nameTextStyle: { fontSize: 12 },
                    splitLine: { lineStyle: {} },
                    axisLine: { show: false },
                    axisLabel: {
                        formatter: `{value} ${unit || "kWh"}`, // Y축 라벨 값 포맷
                    },
                },
                series: [
                    { name: "전력사용량 ", type: "scatter", data: this.chartData },
                    {
                        name: "모델예측값",
                        type: "line",
                        data: regression.points,
                        showSymbol: false,
                        smooth: true,
                        markPoint: {
                            label: {
                                formatter: () => `${regression.expression}`,
                                position: "left",
                                color: "#333",
                                fontSize: 15,
                            },
                            data: [{ coord: regression.points[regression.points.length - 1] }],
                        },
                    },
                ],
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        },
    },
};
</script>

<style scoped>
.externalStateMenu {
    display: flex;
    justify-content: flex-end;
}

.table-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
}

.equationContainer {
    display: flex;
    justify-content: center;
    background-color: #555;
    color: yellow;
    border-radius: 5px;
    line-height: 35px;
    font-weight: 600;
}
.resultComment {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 52px;
    margin-bottom: 20px;
}
.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.status-icon.active {
    color: #fff;
    background-color: #555;
}
.status-icon:focus,
.status-icon:active {
    outline: none;
    box-shadow: none;
}
.statisticButtonContainer {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
}
.msgBox {
    margin-top: 4px;
    padding: 4px;
    border: solid #bbb 1px;
    border-radius: 5px;
}
</style>
