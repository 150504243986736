<template>
    <div>
        <b-modal centered ref="statusDetail" size="xl" @hidden="hideStatusDetailModal">
            <template #modal-header>
                <span class="ctrl-modal-header">영향 인자 목록</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideStatusDetailModal">
                        닫기
                    </button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <div style="width: 100%; display: flex; justify-content: end;">
                        <button
                            type="button"
                            class="status-icon"
                            @click="deleteRltnPoint"
                            v-b-tooltip.hover
                            :title="$t('영향 인자 삭제')"
                        >
                            <i class="fas fa-minus"></i>
                        </button>
                        <button
                            type="button"
                            class="status-icon"
                            @click="registNewRltn"
                            v-b-tooltip.hover
                            :title="$t('영향 인자 추가')"
                        >
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <TableListComponent
                        ref="TableListComponent"
                        :title="''"
                        :columns="columns"
                        :rows="statusDetail"
                        :useFilter="false"
                        :transCodes="transCodes"
                        :keyField="'sysNodeName'"
                        :mode="'edit'"
                        :customMaxHeight="'65vh'"
                        :customRowHeight="'55%'"
                    />
                </div>
            </div>
        </b-modal>
        <RelationPointModal ref="RelationPointModal" :data="currentNode" :type="'ctrl'" @save="addRltnPoint" />
    </div>
</template>

<script>
import TableListComponent from "@src/views/component/v2.1/ListDetailView/TableList.vue";
import RelationPointModal from "@views/new/management-info/equip-sys-mgmt/modal/RelationPointModal.vue";
// import moment from 'moment'
import backEndApi from "@src/api/backEndApi";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["statusDetail", "currentNode", "statusDate"],
    components: {
        TableListComponent,
        RelationPointModal,
    },
    data() {
        return {
            columns: [
                {
                    label: this.$t("표시명"),
                    field: "dispText",
                    type: "text",
                },
                {
                    label: this.$t("계통 노드"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ["sysNodeName", "sysNodeIdx"],
                },
                {
                    label: this.$t("관계유형"),
                    field: "ptReltnType",
                    type: "text",
                },
                {
                    label: this.$t("영향범위"),
                    field: "affectRange",
                    type: "text",
                },
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    type: "text",
                },
                {
                    label: this.$t("관제점 주소"),
                    field: "ptAddr2",
                    type: "text",
                    compositionFields: ["ptAddr", "ptName"],
                },
                {
                    label: this.$t("집계유형"),
                    field: "ptTypeCode",
                    type: "text",
                },
                {
                    label: this.$t("현재값"),
                    field: "ptVal",
                    type: "text",
                },
                {
                    label: this.$t("단위"),
                    field: "unitSmallCode",
                    type: "text",
                },
                {
                    label: this.$t("갱신시간"),
                    field: "updDt",
                    type: "text",
                    // formatter: (value) => {
                    //     // ISO8601 날짜를 'YYYY-MM-DD HH:mm:ss' 형식으로 변환
                    //     return moment(value).format('YYYY-MM-DD HH:mm:ss');
                    // }
                },
            ],
            transCodes: [
                {
                    field: "ptReltnType",
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
                {
                    field: "ptTypeCode",
                    codes: this.$store.state.commonCodes.ptTypeCode,
                },
                {
                    field: "affectRange",
                    codes: this.$store.state.commonCodes.affectRange,
                },
                {
                    field: "unitSmallCode",
                    codes: this.$store.state.units,
                },
            ],
            popupMessages,
        };
    },
    watch: {},
    computed: {},
    created() {
        // this.statusDetail.updDt = moment(this.statusDetail.updDt).format("YYYY-MM-DD HH:mm");
    },
    mounted() {},
    destroyed() {},
    methods: {
        showStatusDetailModal() {
            this.$refs.statusDetail.show();
        },
        hideStatusDetailModal() {
            this.$refs.statusDetail.hide();
            this.$emit("clear-data");
        },
        async registNewRltn() {
            await this.$refs.RelationPointModal.setData();
            await this.$refs.RelationPointModal.showRelationPointModal();
        },

        async addRltnPoint() {
            const point = this.$refs.RelationPointModal.ptInfo;
            const newRltn = {
                sysNodeIdx: this.currentNode.sysNodeIdx,
                ptIdx: point.pointInfo.ptIdx,
                dispText: point.dispText,
                ptReltnType: point.ptReltnType,
                affectRange: point.affectRange,
                enabled: point.enabled,
            };

            try {
                const result = await backEndApi.sysNode.addReltnPoint(newRltn);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    await this.$refs.RelationPointModal.showRelationPointModal();
                    this.hideStatusDetailModal();
                    await this.$emit("reload", this.currentNode, this.statusDate);
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },

        async deleteRltnPoint() {
            const select = this.$refs.TableListComponent.getCheckedRows();

            if (this.isEmpty(select)) {
                this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
            } else {
                const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;
                else {
                    const deleteRltn = {
                        sysNodeIdx: this.currentNode.sysNodeIdx,
                        ptIdx: select[0].ptIdx,
                    };

                    try {
                        const result = await backEndApi.sysNode.deleteReltnPoint(deleteRltn);
                        if (result.status == 200) {
                            await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                            this.hideStatusDetailModal();
                            await this.$emit("reload", this.currentNode, this.statusDate);
                        } else {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}
</style>
