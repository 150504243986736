<template>
    <vvo v-slot="v">
        <div class="row flex-grow-0 mb-2">
            <div class="col">
                <!-- TableList -->
                <TableListComponent
                    ref="TableListComponent"
                    :title="''"
                    :columns="columns"
                    :rows="detailInfo.ioProps"
                    :keyField="'propCode'"
                    :transCodes="transCodes"
                    :mode="mode"
                    :useMaxHeight="false"
                    :fixedHeader="false"
                    :toggleFields="['enabled']"
                    :useFilter="false"
                    :controls="controls"
                    @row-click="handleListItemClick"
                    @toggle-changed="handleItemToggle"
                    @button-click="handleListButtonClick"
                />

                <b-modal ref="IoPropDetailModal" centered @hidden="closeIoPropModal">
                    <template #modal-header>
                        <h5>계측 속성</h5>
                    </template>
                    <template #modal-footer centered>
                        <!-- @click="handleAddListItem" -->
                        <div style="display: flex; flex: 1;">
                            <button
                                v-show="isEditMode"
                                type="button"
                                class="btn btn-primary"
                                style="flex: 1;"
                                :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                                @click="onValidate(v, handleAddListItem)"
                            >
                                저장
                            </button>
                            <!-- @click="closeIoPropModal" -->
                            <button
                                type="button"
                                class="btn btn-secondary"
                                style="flex: 1;"
                                :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                                @click="closeIoPropModal"
                            >
                                닫기
                            </button>
                        </div>
                    </template>

                    <IoPropDetailPanel ref="IoPropDetailPanel" :mode="mode" />
                </b-modal>
            </div>
        </div>
    </vvo>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
// import PropsModalComponent from "../modal/PropsModal.vue"
import IoPropDetailPanel from "./IoPropDetailPanel.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableListComponent,
        // PropsModalComponent,
        IoPropDetailPanel,
    },
    props: ["detailInfo", "mode"],
    created() {},
    mounted() {},
    data() {
        return {
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-warning" },
            ],
            transCodes: [
                {
                    field: "dataSourceType",
                    codes: this.$store.state.commonCodes.dataSourceType,
                },
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: "unitType",
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t("속성 코드"),
                    field: "propCode",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                    // compositionFields: ['propName', 'propCode']
                },
                {
                    label: this.$t("속성명"),
                    field: "propName",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                // {
                //     label: this.$t('관제 유형'),
                //     field: 'dataSourceType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t("연결 관제점 주소"),
                    field: "ptAddr",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center custom-ptIdx",
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                // {
                //     label: this.$t('단위 유형'),
                //     field: 'unitType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t("단위"),
                    field: "unit",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("계측값"),
                    field: "ptVal",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
            ],
            // tempIoProp: null,
            tempIoPropIndex: null,
            popupMessages,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {},
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if (toggle || check) return;

            this.tempIoPropIndex = index;
            let findIoProp = this.detailInfo.ioProps.find((item) => item.propCode == row.propCode);
            await this.$refs.IoPropDetailModal.show();
            await this.$refs.IoPropDetailPanel.setData(findIoProp);
        },
        async closeIoPropModal() {
            await this.$refs.IoPropDetailModal.hide();
            if (this.tempIoPropIndex !== null || undefined) this.tempIoPropIndex = null;
        },
        handleAddListItem() {
            const ioProp = this.$refs.IoPropDetailPanel.getData();

            if (ioProp) {
                if (this.tempIoPropIndex !== null || undefined) {
                    this.$set(this.detailInfo.ioProps, this.tempIoPropIndex, ioProp);
                    this.tempIoPropIndex = null;
                } else {
                    // Check Duplication (propCode, propName)
                    let check = this.detailInfo.ioProps.find(
                        (prop) => prop.propCode === ioProp.propCode || prop.propName === ioProp.propName
                    );
                    if (check) {
                        // TODO: Alert Message
                        console.error("TODO: Alert Message");
                        console.error(
                            `In Property is already exists in ioProps. propCode:${ioProp.propCode}, propName:${ioProp.propName}`
                        );
                    } else {
                        this.$set(this.detailInfo.ioProps, this.detailInfo.ioProps.length, ioProp);
                    }
                }
            }

            this.$refs.IoPropDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs["TableListComponent"].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.ioProps = that.detailInfo.ioProps.filter((prop) => {
                            return !checked.find((checkedProp) => checkedProp.propCode === prop.propCode);
                        });
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async registNew() {
            const that = this;

            that.$refs.IoPropDetailModal.show();
            that.$nextTick(() => {
                that.$refs.IoPropDetailPanel.setData(null);
            });
        },
        handleItemToggle(key, field, status) {
            const that = this;
            const found = that.detailInfo.ioProps.find((item) => item.propCode === key);
            found.enabled = status;
        },
    },
};
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>
