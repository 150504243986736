import { render, staticRenderFns } from "./PointListPanel.vue?vue&type=template&id=b98c960c&scoped=true&"
import script from "./PointListPanel.vue?vue&type=script&lang=js&"
export * from "./PointListPanel.vue?vue&type=script&lang=js&"
import style0 from "./PointListPanel.vue?vue&type=style&index=0&id=b98c960c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b98c960c",
  null
  
)

export default component.exports