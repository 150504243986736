<template>
    <div>
        <b-modal centered ref="CtrlLog" @hidden="hideControlLogModal">
            <template #modal-header>
                <span class="ctrl-modal-header">제어 이력</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideControlLogModal">
                        닫기
                    </button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="outputIdx">제어 ID</label>
                            <input
                                type="text"
                                class="form-control"
                                id="outputIdx"
                                disabled
                                :value="ctrlLog.outputIdx"
                            />
                        </div>
                        <div class="form-group col-md-5">
                            <label for="updDtISO">제어 실행 일자</label>
                            <input type="text" class="form-control" id="updDtISO" disabled :value="ctrlLog.setDtISO" />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="ctrlTypeCode">제어 형태</label>
                            <input
                                type="text"
                                class="form-control"
                                id="ctrlTypeCode"
                                disabled
                                :value="getCtrlTypeText(ctrlLog.ctrlTypeCode)"
                            />
                        </div>
                        <div class="form-group col-md-2">
                            <label for="state">제어 결과</label>
                            <input type="text" class="form-control" id="state" disabled :value="ctrlLog.state" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="ctrlTargetCode">제어 구분</label>
                            <input
                                type="text"
                                class="form-control"
                                id="ctrlTargetCode"
                                disabled
                                :value="getCtrlTargetText(ctrlLog.ctrlTargetCode)"
                            />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="ctrlTarget">제어 대상</label>
                            <input
                                type="text"
                                class="form-control"
                                id="ctrlTarget"
                                disabled
                                :value="`${ctrlLog.target.targetName} (${ctrlLog.target.targetIdx})`"
                            />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="ctrlMode">제어 모드</label>
                            <input type="text" class="form-control" id="ctrlMode" disabled :value="ctrlLog.ctrlMode" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="ctrlOutPut">제어 명령</label>
                            <input type="text" class="form-control" id="ctrlOutPut" disabled :value="ctrlOutPutStr" />
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

// import backEndApi from '@src/api/backEndApi';

export default {
    props: ["ctrlLog"],
    components: {},
    data() {
        return {
            ctrlOutPutStr: "",
        };
    },
    watch: {},
    computed: {
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
    },
    created() {
        this.ctrlLog.updDtISO = moment(this.ctrlLog.updDtISO).format("YYYY-MM-DD HH:mm:ss");

        this.ctrlOutPutStr = Object.entries(this.ctrlLog.ctrlOutPut)
            .map(([key, val]) => `${key}:${typeof val === "string" ? `"${val}"` : val}`)
            .join(",");
    },
    mounted() {},
    destroyed() {},
    methods: {
        showControlLogModal() {
            this.$refs.CtrlLog.show();
        },
        hideControlLogModal() {
            this.$refs.CtrlLog.hide();
            this.$emit("clear-data");
        },
        getCtrlTypeText(ctrlTypeCode) {
            const codeItem = this.commonCodes.ctrlTypeCode.find((item) => item.value == ctrlTypeCode);
            return codeItem ? codeItem.text : "-";
        },
        getCtrlTargetText(ctrlTargetCode) {
            const codeItem = this.commonCodes.ctrlTargetCode.find((item) => item.value == ctrlTargetCode);
            return codeItem ? codeItem.text : "-";
        },
    },
};
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.form-check {
    display: flex !important;
    align-items: center;
    margin-left: 1rem;
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.list-con {
    border-radius: 8px;
    border: solid #eee 1px;
    padding: 1rem;
}
</style>
