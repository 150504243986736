import { generateColorGradient } from "../../lib/colorGradient";
import store from "@src/config/PageStore.vue";
import xelib from "xelib";
export default {
    reorderObject(obj) {
        const { summary, ...rest } = obj;
        return { summary, ...rest };
    },
    formatChartDatas(baseData, currentNode, rows, queryType) {
        // let customData;
        if (!baseData.summary) delete baseData.summary;
        return Object.keys(baseData).map((key, index) => {
            const headers = baseData[key].headers;
            const results = baseData[key].results;
            const startColor = "#E3F2FD";
            const endColor = "#0D47A1";
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);

            return {
                key: `Chart${index}`,
                flag: true,
                data: {
                    labels: results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),
                    datasets: headers.map((header, headerIndex) => {
                        const unit = store.state.units.find((unit) => unit.value == header.unitSmallCode)?.text;
                        const borderColor = "#90909077";
                        const backgroundColor = backgroundColors[headerIndex];

                        return {
                            key: header.dispText ? header.dispText : header.propName,
                            propCode: header.propCode ? header.propCode : "",
                            unit: unit,
                            type: "line",
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            label: header.dispText
                                ? `${header.dispText} ${unit !== "-" && unit ? `(${unit})` : ""}`
                                : header.propName,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor: backgroundColor + "44",
                            data: results.map((result) =>
                                result[header.ptIdx] === undefined ? result[header.propCode] : result[header.ptIdx]
                            ),
                        };
                    }),
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: rows.find((row) => row.equipIdx == key)
                            ? rows.find((row) => row.equipIdx == key).equipName
                            : "대표값 집계",
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20, // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${
                                    dataset.unit !== "-" && dataset.unit ? dataset.unit : ""
                                }`;
                            },
                        },
                    },
                },
            };
        });
    },
    formatSystemDatas(baseData, currentNode, rows, queryType) {
        let customData = {};
        Object.keys(baseData).forEach((key) => {
            Object.keys(baseData[key]).forEach((key2) => {
                if (!baseData[key][key2]) {
                    delete baseData[key][key2];
                } else customData[key2] = baseData[key][key2];
            });
        });
        return Object.keys(customData).map((key, index) => {
            const headers = customData[key].headers;
            const results = customData[key].results;
            const startColor = "#E3F2FD";
            const endColor = "#0D47A1";
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);

            return {
                key: `Chart${index}`,
                flag: true,
                data: {
                    labels: results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),
                    datasets: headers.map((header, headerIndex) => {
                        const unit = store.state.units.find((unit) => unit.value == header.unitSmallCode)?.text;
                        const borderColor = "#90909077";
                        const backgroundColor = backgroundColors[headerIndex];

                        return {
                            key: header.dispText ? header.dispText : header.propName,
                            propCode: header.propCode ? header.propCode : "",
                            unit: unit,
                            type: "line",
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            label: header.dispText ? header.dispText + `(${unit ? unit : "-"})` : header.propName,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor: backgroundColor + "44",
                            data: results.map((result) =>
                                result[header.ptIdx] === undefined ? result[header.propCode] : result[header.ptIdx]
                            ),
                        };
                    }),
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: rows.find((row) => row.equipIdx == key)
                            ? rows.find((row) => row.equipIdx == key).equipName
                            : "집계값",
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20, // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ""}`;
                            },
                        },
                    },
                },
            };
        });
    },
    formatChartDatasByProp(baseData, equipName, queryType) {
        delete baseData.summary;
        const gradientLevel = Object.entries(baseData).length;
        // const startColor = '#ECEFF1';
        // const endColor = '#263238';
        const startColor = "#FBE9E7";
        const endColor = "#BF360C";

        const backgroundColors = generateColorGradient(startColor, endColor, gradientLevel - 1);

        const getPropCodes = (baseData) => {
            const propCodesMap = new Map();
            Object.values(baseData).forEach((data) => {
                data.headers.forEach((header) => {
                    const { propCode, dispText, unitSmallCode } = header;
                    const unit = store.state.units.find((unit) => unit.value == unitSmallCode)?.text;
                    if (!propCodesMap.has(propCode)) {
                        propCodesMap.set(propCode, { dispText, unit });
                    }
                });
            });
            return Array.from(propCodesMap, ([propCode, { dispText, unit }]) => ({ propCode, dispText, unit }));
        };

        const propCodes = getPropCodes(baseData);

        const borderColor = "#90909044";

        return propCodes.map((prop) => {
            const datasets = [];

            Object.keys(baseData).forEach((key, index) => {
                const headers = baseData[key].headers.filter((header) => header.propCode === prop.propCode);
                const results = baseData[key].results;

                headers.forEach((header) => {
                    datasets.push({
                        key: `${key}_${header.dispText}`,
                        propCode: header.propCode,
                        propName: header.dispName,
                        unit: header.unit,
                        type: "line",
                        lineTension: 0.5,
                        borderWidth: 1.3,
                        fill: true,
                        // label: `${point ? point.equipName : 'EMPTY'}`,
                        label: equipName.find((item) => item.equipIdx == key).equipName ?? "",
                        pointRadius: 2,
                        showLine: true,
                        interpolate: true,
                        borderColor: borderColor,
                        backgroundColor: backgroundColors[index] + "44",
                        data: results.map((result) =>
                            result[header.ptIdx] === undefined ? null : result[header.ptIdx]
                        ),
                    });
                });
            });

            return {
                key: `Chart_${prop.propCode}`,
                flag: true,
                data: {
                    labels: Object.values(baseData)[0].results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),
                    datasets: datasets,
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text:
                            prop.unit !== "-" && prop.unit
                                ? `${prop.dispText ?? prop.propCode} (${prop.unit})`
                                : `${prop.dispText ?? prop.propCode}`,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        mode: "index",
                        intersect: true,
                        animationDuration: 400,
                    },
                    // elements: {
                    //     point: {
                    //         hitRadius: 20,
                    //     },
                    // },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    callback: function(value) {
                                        return `${value} ${prop.unit !== "-" && prop.unit ? prop.unit : ""}`;
                                    },
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.label}: ${value} ${prop.unit !== "-" && prop.unit ? prop.unit : ""}`;
                            },
                        },
                    },
                },
            };
        });
    },

    equipFormatChartDatas(baseData, typeDetail, currentNode, rows, queryType) {
        delete baseData.summary;
        const charts = [];

        Object.keys(baseData).forEach((key, index) => {
            const headers = baseData[key].headers.filter((item) =>
                typeDetail.ioProps.some((type) => type.propCode == item.propCode)
            );
            const findCalc = baseData[key].headers.filter((item) =>
                typeDetail.calcProps.some((type) => type.propCode == item.propCode)
            );
            const findUser = baseData[key].headers.filter((item) =>
                typeDetail.userProps.some((type) => type.propCode == item.propCode)
            );
            if (findCalc.length > 0) headers.push(...findCalc);
            if (findUser.length > 0) headers.push(...findUser);
            const results = baseData[key].results;

            const startColor = "#ECEFF1";
            const endColor = "#263238";
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);

            headers.forEach((header, headerIndex) => {
                const backgroundColor = backgroundColors[headerIndex]; // 단일 색상
                const unit = store.state.units.find((unit) => unit.value == header.unitSmallCode)?.text;
                const borderColor = "#90909077";

                charts.push({
                    key: `Chart${index}_${header.ptIdx}`,
                    flag: true,
                    data: {
                        labels: results.map((result) => {
                            const regDt = result.regDt.replace(/[-:TZ]/g, "");
                            const hour = regDt.substring(8, 10);
                            const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                            return returnDate;
                        }),
                        datasets: [
                            {
                                key: header.dispText,
                                propCode: header.propCode,
                                unit: unit,
                                type: "line",
                                lineTension: 0.5,
                                borderWidth: 1.3,
                                fill: true,
                                // label: header.dispText + `(${unit ?? '-'})`,
                                label: unit ? header.dispText + `(${unit})` : header.dispText,
                                pointRadius: 2,
                                showLine: true,
                                interpolate: true,
                                borderColor,
                                backgroundColor: backgroundColor + "44",
                                data: results.map((result) =>
                                    result[header.ptIdx] === undefined ? null : result[header.ptIdx]
                                ),
                            },
                        ],
                    },
                    option: {
                        interaction: {
                            mode: "index",
                            intersect: false,
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: {
                            duration: 1000,
                            easing: "easeOutQuart",
                        },
                        // hoverMode: "index",
                        legend: {
                            display: true,
                            align: "end",
                            labels: {
                                boxWidth: 12,
                                fontColor: "#000000",
                                fontSize: 12,
                            },
                        },
                        title: {
                            display: true,
                            align: "start",
                            position: "top",
                            text:
                                currentNode.nodeType == "Group"
                                    ? rows.find((row) => row.equipIdx == key).equipName
                                    : header.dispText,
                            fontSize: 12,
                            fontColor: "#000000",
                        },
                        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                        hover: {
                            // mode: 'index',
                            // intersect: true,
                            // animationDuration: 400,

                            intersect: false,
                        },
                        elements: {
                            point: {
                                hitRadius: 20, // 포인트 클릭 반경 조절
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    id: "x-axis-0",
                                    stacked: false,
                                    offset: true,
                                    ticks: {
                                        maxTicksLimit: 10,
                                        autoSkip: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                        maxRotation: 0,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    id: "Left_yAxis",
                                    display: true,
                                    position: "left",
                                    stacked: false,
                                    ticks: {
                                        maxTicksLimit: 5,
                                        beginAtZero: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                    },
                                },
                            ],
                        },
                        tooltips: {
                            mode: "index",
                            intersect: false,
                            titleFontSize: 14,
                            bodyFontSize: 12,
                            enabled: false,
                            // mode: "index",
                            // intersect: false,
                            // position: "nearest",
                            custom: function(tooltipModel) {
                                // Tooltip Element
                                var tooltipEl = document.getElementById("chartjs-tooltip");
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement("div");
                                    tooltipEl.id = "chartjs-tooltip";
                                    tooltipEl.innerHTML = "<table></table>";
                                    document.body.appendChild(tooltipEl);
                                }

                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                tooltipEl.style.position = "absolute";
                                tooltipEl.style.zIndex = 10000;
                                tooltipEl.style.pointerEvents = "none";
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.transition = "opacity 0.3s ease";

                                var canvasPosition = this._chart.canvas.getBoundingClientRect();
                                tooltipEl.style.left =
                                    canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                                tooltipEl.style.top =
                                    canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                                // tooltipModel의 스타일 값을 툴팁에 적용
                                tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                                tooltipEl.style.borderColor = tooltipModel.borderColor;
                                tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                                tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                                tooltipEl.style.borderStyle = "solid";
                                tooltipEl.style.color = "#fff";
                                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                                var titleLines = tooltipModel.title || [];
                                var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                    return bodyItem.lines;
                                });

                                var innerHtml = "<thead>";
                                titleLines.forEach(function(title) {
                                    innerHtml += "<tr><th>" + title + "</th></tr>";
                                });
                                innerHtml += "</thead><tbody>";

                                bodyLines.forEach(function(body, i) {
                                    var colors = tooltipModel.labelColors[i];
                                    var iconStyle = "background:" + colors.backgroundColor + ";";
                                    // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                    iconStyle += " width: 10px; height: 10px;";
                                    iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                    var iconHtml = '<span style="' + iconStyle + '"></span>';
                                    innerHtml +=
                                        "<tr><td style='display: flex; align-items: center;'>" +
                                        iconHtml +
                                        body +
                                        "</td></tr>";
                                });
                                innerHtml += "</tbody>";

                                var tableRoot = tooltipEl.querySelector("table");
                                tableRoot.innerHTML = innerHtml;
                            },
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                    // var label = dataset.label || '';
                                    // if (label) {
                                    //     label += ': ';
                                    // }
                                    // label += Math.round(tooltipItem.yLabel * 100) / 100;
                                    // return label;

                                    let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return `${dataset.key} : ${value} ${dataset.unit ?? ""}`;
                                },
                            },
                        },
                    },
                });
            });
        });

        return charts;
    },
    formatRltdChartDatas(baseData, queryType) {
        return baseData.headers.map((item) => {
            const { ptIdx, dispText, ptReltnType, unitSmallCode } = item;
            const results = baseData.results;
            const unit = store.state.units.find((unit) => unit.value == unitSmallCode)?.text;
            let backgroundColor, borderColor;
            switch (ptReltnType) {
                case "Factor":
                    backgroundColor = "#CFD8DC66";
                    break;
                case "Cstn":
                    backgroundColor = "#FFCC8066";
                    break;
                case "Prod":
                    backgroundColor = "#B2DFDB66";
                    break;
                default:
                    backgroundColor = "90909044";
                    break;
            }
            borderColor = "#90909077";

            return {
                key: `${ptIdx}-${dispText}`,
                flag: true,
                data: {
                    labels: results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),
                    datasets: [
                        {
                            key: dispText,
                            type: "line",
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            // label: dispText + `(${unit ?? '-'})`,
                            label: unit ? dispText + `(${unit})` : dispText,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor,
                            data: results.map((result) => (result[ptIdx] === undefined ? null : result[ptIdx])),
                        },
                    ],
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    // hoverMode: "index",
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            // usePointStyle: true,
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        // text: dispText,
                        text: unit ? dispText + `(${unit})` : dispText,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        // mode: 'index',
                        // intersect: true,
                        // animationDuration: 400,

                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20, // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var label = dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;
                                return label;
                            },
                        },
                    },
                },
            };
        });
    },
    maxValue(results) {
        let max = 0;
        results.forEach((result) => {
            Object.keys(result).forEach((key) => {
                // regDt 키는 숫자가 아니므로 제외
                if (key !== "regDt") {
                    const value = result[key];
                    if (value > max) {
                        max = value;
                    }
                }
            });
        });
        return max;
    },
    upperNumber(num) {
        const round = Math.round(num);
        const result = Math.round(round / 10) * 10;
        return result;
    },

    sumNodeFormatChartDatas(baseData, queryType) {
        const { Unit } = xelib.PointBoxV2;
        if (!baseData) return [];
        const headers = [
            {
                sysNodeIdx: baseData.headers.sysNodeIdx,
                text: baseData.headers.text,
                nodeType: baseData.headers.nodeType,
                sysType: baseData.headers.sysType,
                equipType: baseData.headers.equipType,
                sumUnit: baseData.headers.sumUnit,
                sumCondType: baseData.headers.sumCondType,
                sumCond: baseData.headers.sumCond,
                equipIdx: baseData.headers.equipIdx,
            },
        ];
        headers.push(...baseData.headers.children);
        const results = baseData.results;
        const startColor = "#E3F2FD";
        const endColor = "#0D47A1";
        const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);
        const borderColor = "#90909077";

        let max = this.maxValue(results);
        let round;
        let upperNum;

        if (max > 1) {
            round = Math.round(max);
            upperNum = Math.ceil(round / 10) * 10;
        }
        return headers.map((header, headerIndex) => {
            // const unit = store.state.units.find((unit) => unit.value == header.sumUnit)?.text;
            // 단위는 kWh로 고정
            const unit = "kWh";
            return {
                key: `${header.sysNodeIdx}-${header.text}`,
                flag: true,
                data: {
                    labels: results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),

                    datasets: [
                        {
                            key: header.text,
                            unit: unit ? unit : "",
                            type: "line",
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            // label: unit ? header.text + `(${unit})` : header.text,
                            label: header.text,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor:
                                headerIndex == 0 ? "rgb(239, 154, 154, 0.5)" : backgroundColors[headerIndex] + "44",
                            data: results.map((result) => {
                                return result[header.sysNodeIdx] === undefined
                                    ? null
                                    : Unit.convert(
                                          parseFloat(result[header.sysNodeIdx]),
                                          header.sumUnit,
                                          "kWh"
                                      ).toFixed(2);
                            }),
                        },
                    ],
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: header.text,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20, // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    max: max > 1 ? upperNum : 10,
                                    callback: function(value) {
                                        return value + unit;
                                    },
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ""}`;
                            },
                        },
                    },
                },
            };
        });
    },
    sumNodeFormatChartDatasForEquip(baseData, queryType) {
        if (!baseData) return [];
        const { Unit } = xelib.PointBoxV2;
        const headers = baseData.headers.ptMaps;
        const results = baseData.results;
        const startColor = "#E3F2FD";
        const endColor = "#0D47A1";
        const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);
        const borderColor = "#90909077";

        let max = this.maxValue(results);
        let round;
        let upperNum;

        if (max > 1) {
            round = Math.round(max);
            upperNum = Math.ceil(round / 10) * 10;
        }

        return headers.map((point, pointIndex) => {
            // const unit = store.state.units.find((unit) => unit.value == point.sumUnit)?.text;
            // 단위는 kWh로 고정
            const unit = "kWh";
            return {
                key: `${point.ptIdx}-${point.ptName}`,
                flag: true,
                data: {
                    labels: results.map((result) => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, "");
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== "MONTH" ? `${hour}:00` : result.regDt;
                        return returnDate;
                    }),
                    datasets: [
                        {
                            key: point.ptName,
                            unit: unit ? unit : "",
                            type: "line",
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            label: unit ? point.ptName + `(${unit})` : point.ptName,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor:
                                pointIndex == 0 ? "rgb(239, 154, 154, 0.5)" : backgroundColors[pointIndex] + "44",
                            // data: results.map(result => result[point.sysNodeIdx] === undefined ? null : result[point.sysNodeIdx])
                            data: results.map((result) => {
                                const key = `PT-${point.ptIdx}`;
                                let value;
                                if (result[key] == undefined) value = null;
                                else value = result[key];

                                return Unit.convert(parseFloat(value, point.sumUnit), "kWh").toFixed(2);
                            }),
                        },
                    ],
                },
                option: {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12,
                        },
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: point.ptName,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20, // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    max: max > 1 ? upperNum : 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        titleFontSize: 14,
                        bodyFontSize: 12,
                        enabled: false,
                        // mode: "index",
                        // intersect: false,
                        // position: "nearest",
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById("chartjs-tooltip");
                            if (!tooltipEl) {
                                tooltipEl = document.createElement("div");
                                tooltipEl.id = "chartjs-tooltip";
                                tooltipEl.innerHTML = "<table></table>";
                                document.body.appendChild(tooltipEl);
                            }

                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            tooltipEl.style.position = "absolute";
                            tooltipEl.style.zIndex = 10000;
                            tooltipEl.style.pointerEvents = "none";
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.transition = "opacity 0.3s ease";

                            var canvasPosition = this._chart.canvas.getBoundingClientRect();
                            tooltipEl.style.left =
                                canvasPosition.left + window.pageXOffset + tooltipModel.caretX + "px";
                            tooltipEl.style.top = canvasPosition.top + window.pageYOffset + tooltipModel.caretY + "px";

                            // tooltipModel의 스타일 값을 툴팁에 적용
                            tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                            tooltipEl.style.borderColor = tooltipModel.borderColor;
                            tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                            tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                            tooltipEl.style.borderStyle = "solid";
                            tooltipEl.style.color = "#fff";
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";

                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(function(bodyItem) {
                                return bodyItem.lines;
                            });

                            var innerHtml = "<thead>";
                            titleLines.forEach(function(title) {
                                innerHtml += "<tr><th>" + title + "</th></tr>";
                            });
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var iconStyle = "background:" + colors.backgroundColor + ";";
                                // iconStyle += " border: 2px solid " + colors.borderColor + ";";
                                iconStyle += " width: 10px; height: 10px;";
                                iconStyle += " display: inline-block; margin-right: 4px; vertical-align: middle;";
                                var iconHtml = '<span style="' + iconStyle + '"></span>';
                                innerHtml +=
                                    "<tr><td style='display: flex; align-items: center;'>" +
                                    iconHtml +
                                    body +
                                    "</td></tr>";
                            });
                            innerHtml += "</tbody>";

                            var tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        },
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ""}`;
                            },
                        },
                    },
                },
            };
        });
    },
};
