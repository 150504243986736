<template>
    <div class="sankeyContainer">
        <div ref="sankeyChart" style="width: 100%; height: 700px"></div>

        <!-- <b-modal ref="childNodeInfoModal" centered size="lg" @hidden="closeChildNodeInfoModal">
            <template #modal-header>
                <h5>부하 구성 목록</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button type="button" class="btn btn-secondary" style="flex: 1" @click="closeChildNodeInfoModal">
                        닫기
                    </button>
                </div>
            </template>
        </b-modal> -->
    </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
    props: ["data", "phscPointUsageMap"],
    data() {
        return {
            unitMap: null,
        };
    },
    mounted() {
        this.nodeUnit();
        this.renderChart();
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.nodeUnit();
                this.renderChart();
            },
        },
    },
    computed: {
        ...mapState({
            zoneInfo: (state) => state.zoneInfo,
        }),
    },
    methods: {
        nodeUnit() {
            if (!this.data.links?.length) return;

            this.unitMap = new Map();
            this.data.links.forEach((link) => {
                this.unitMap.set(link.source, link.unit);
            });
        },

        closeChildNodeInfoModal() {
            this.$refs.childNodeInfoModal.hide();
        },

        onNodeSelect() {
            this.$refs.childNodeInfoModal.show();
        },

        isStringNumber(str) {
            return typeof str === "string" && str.trim() !== "" && !isNaN(str);
        },

        // ✅ 통합된 이름 표시 처리 함수
        resolveDisplayName(item) {
            // 문자열로 들어온 경우
            if (typeof item === "string") {
                const replaced = this.phscPointUsageMap.get(item);
                if (!replaced && this.isStringNumber(item)) {
                    const zone = this.zoneInfo.find((z) => z.value === Number(item));
                    return zone?.text || item;
                }
                return replaced || item;
            }

            // node 객체
            if (item?.data) {
                const { name, type } = item.data;

                if (type === "engUseType") {
                    return this.phscPointUsageMap.get(name) || name;
                }

                if (type === "zoneIdx" && this.isStringNumber(name)) {
                    const zone = this.zoneInfo.find((z) => z.value === Number(name));
                    return zone?.text || name;
                }

                return name;
            }

            return item;
        },

        renderChart() {
            const chart = echarts.init(this.$refs.sankeyChart);

            const option = {
                tooltip: {
                    trigger: "item",
                    triggerOn: "mousemove",
                    formatter: function(params) {
                        if (params.dataType === "edge") {
                            return `${this.resolveDisplayName(params.data.source)} ▶ ${this.resolveDisplayName(
                                params.data.target
                            )} <span style="font-weight: bold;"> ${Number(params.data.value).toFixed(3)} ${
                                params.data.unit
                            }</span>`;
                        } else if (params.dataType === "node") {
                            const inputs = this.data.links
                                .filter((link) => link.target === params.name)
                                .map(
                                    (link) =>
                                        `${this.resolveDisplayName(link.source)} ▶ ${this.resolveDisplayName(
                                            link.target
                                        )}: ${Number(link.value).toFixed(3)} ${link.unit || "kWh"}`
                                );

                            const outputs = this.data.links
                                .filter((link) => link.source === params.name)
                                .map(
                                    (link) =>
                                        `${this.resolveDisplayName(link.source)} ▶ ${this.resolveDisplayName(
                                            link.target
                                        )}: ${Number(link.value).toFixed(3)} ${link.unit || "kWh"}`
                                );

                            return `
                            ${this.resolveDisplayName(params)}<br/>
                            <span style="font-weight: bold;">${Number(params.value).toFixed(3)} ${this.unitMap?.get(
                                params.name
                            ) || "kWh"}</span>
                            <br/>
                            <small>
                            ${inputs.length ? `<b>Inputs</b><br/>${inputs.join("<br/>")}` : ""}
                            ${outputs.length ? `<br/><b>Outputs</b><br/>${outputs.join("<br/>")}` : ""}
                            </small>`;
                        }
                    }.bind(this),
                },

                series: [
                    {
                        type: "sankey",
                        data: this.data.data,
                        links: this.data.links,
                        emphasis: {
                            focus: "adjacency",
                        },
                        draggable: true,
                        left: "center",
                        top: "middle",
                        layout: "none",
                        width: "85%",
                        height: "95%",
                        label: {
                            show: true,
                            formatter: function(params) {
                                const value = Number(params.value);
                                if (value === 0) return "";

                                return `{name|${this.resolveDisplayName(params)}}\n{value|${value.toFixed(
                                    3
                                )} ${this.unitMap.get(params.name) || "kWh"}}`;
                            }.bind(this),
                            rich: {
                                name: {
                                    fontWeight: "bold",
                                    color: "#444",
                                    fontSize: 12,
                                    lineHeight: 20,
                                },
                                value: {
                                    fontWeight: 500,
                                    color: "#888",
                                    fontSize: 12,
                                },
                            },
                        },
                        lineStyle: {
                            color: "gradient",
                            curveness: 0.1,
                        },
                    },
                ],
            };

            chart.setOption(option);

            chart.on("click", (params) => {
                if (params.dataType === "node") {
                    this.onNodeSelect(params);
                }
            });

            window.addEventListener("resize", () => {
                chart.resize();
            });
        },
    },
};
</script>

<style scoped>
.sankeyContainer {
    margin-top: 50px;
}
</style>
